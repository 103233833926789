<template>
  <div>
    <div class="nav">
      <img src="../assets/logo.svg" class="logo" />
      <div class="menu" :class="{ openmenu: opennavbar }">
        <button type="button" class="btn-close" @click="opennavbar = false">
          <img src="../assets/close-white.svg" />
        </button>
        <a href="/#Portfolio" class="nav-link" @click="opennavbar = false">Portfolio</a>
        <a href="/#Team" class="nav-link" @click="opennavbar = false">Team</a>
        <a href="/#About" class="nav-link" @click="opennavbar = false">About</a>
        <a href="/#Blog" class="nav-link" @click="opennavbar = false">Blog</a>
        <a href="/#Contact" class="nav-link" @click="opennavbar = false">Contact</a>
      </div>
      <button type="button" class="menu-btn" @click="opennavbar = true">
        <img src="../assets/menu.svg" />
      </button>
    </div>
    <div class="hero">
      <model-viewer auto-rotate="true" disable-zoom="" interaction-prompt="none" auto-rotate-delay="500" class="crystal" src="Crystal-purple.glb" environment-image="bg.jpg" poster="Crystal.png"
        camera-controls="" touch-action="pan-y" generate-schema="" />
      <div>
        <img src="../assets/logo.svg" class="hero-logo-block" />
        <div class="subs txt24" v-html="$local.homepage.welcomeText"></div>
        <div class="top-soc-wrap">
          <a :href="social.link" class="soc-top-link" target="_blank" v-for="social in $local.homepage.social" :key="social._id">
            <img :src="social.logo.imageURL" /></a>
        </div>
      </div>
    </div>
    <div class="portfolio-wrap" id="Portfolio">
      <div class="portfolio">
        <div class="mentorship-top" style="max-width: 520px; margin: auto">
          <div class="mentorship-line short"></div>
          <h3 class="txt24 item-head bodoni" style="padding-inline: 24px">
            Our Portfolio
          </h3>
          <div class="mentorship-line short"></div>
        </div>
        <p>
          {{ $local.homepage.portfolioText }}
        </p>
      </div>
      <div class="companies" id="Portfolio">
        <a :href="company.link" target="_blank" class="company" v-for="company in $local.companies" :key="company._id">
          <img :src="company.logo.imageURL" class="company-logo" :alt="company.name" />
          <div class="acquired">
            {{ company.outcomeTitle }}
            <p v-if="company.outcomeLabel" class="acquired-box">
              {{ company.outcomeLabel }}
            </p>
            <img class="acquired-box" v-if="company.outcomeLogo && company.outcomeLogo.imageURL" :src="company.outcomeLogo.imageURL" height="18" />
          </div>
        </a>
      </div>
    </div>
    <div class="team" id="Team">
      <h3 class="bodoni team-head">Team</h3>
      <div class="team-carousel">
        <carousel :loop="false" :autoWidth="true" :nav="true" :margin="0" :dots="false">
          <a :href="member.linkedin" class="member" target="_blank" v-for="member in $local.team" :key="member._id">
            <div class="prodfile">
              <img :src="member.image.imageURL" />
            </div>
            <p class="name">{{ member.name }}</p>
            <p class="title">{{ member.title }}</p>
          </a>
        </carousel>
      </div>
    </div>
    <div class="mentorship" id="About">
      <div class="mentorship-top">
        <div class="mentorship-line"></div>
        <img src="../assets/logo-shape.svg" class="mentorship-icon" />
        <div class="mentorship-line"></div>
      </div>
      <h3 class="txt70 bodoni"> {{ $local.homepage.mentorship }}</h3>
      <p class="txt24">{{ $local.homepage.mentorshipSubtitle }}</p>
      <p>{{ $local.homepage.mentorshipTagline }}</p>
    </div>
    <div class="list">
      <div class="list-col">

        <div class="list-item" v-for="item in $local.homepage.mentorshipList.slice(0, 3)" :key="item._id">
          <h4 class="item-head bodoni">{{ item.title }}</h4>
          <p class="list-txt" v-html="item.body">
          </p>
        </div>

      </div>
      <div class="list-line"></div>
      <div class="list-col">
        <div class="list-item" v-for="item in $local.homepage.mentorshipList.slice(3, 6)" :key="item._id">
          <h4 class="item-head bodoni">{{ item.title }}</h4>
          <p class="list-txt" v-html="item.body">
          </p>
        </div>
      </div>
    </div>
    <div class="partner">
      <p>If you believe that we are a good partner for you, then please</p>
      <a href="/#Contact" class="drop">drop us a line <img src="../assets/small arrow.svg" /></a>
    </div>
    <div class="blogs-sec">
      <div class="blog" id="Blog">
        <div class="contact-head-wrap">
          <h4 class="txt70 bodoni">Our Latest Blog Posts</h4>
          <img src="../assets/arrow.svg" class="big-arrow" />
        </div>
        <div class="blogs">
          <a :href="blog.link" target="_blank" class="blog-item" v-for="blog in $local.blogPosts.slice(0, 3)" :key="blog._id">
            <div class="blog-item-top">
              <p class="blog-date">
                {{ new Date(blog.publishDate).toLocaleDateString() }}
              </p>
              <p class="blog-title">{{ blog.title }}</p>
            </div>
            <div v-html="blog.blurb" class="blog-txt"></div>
          </a>

        </div>
        <button v-if="!moreblogs" class="more-blogs-btn" @click="moreblogs = true">View More</button>
        <div class="more-blogs" :class="{ showMoreBlogs: moreblogs }">
          <a :href="blog.link" target="_blank" class="blog-item" v-for="blog in $local.blogPosts.slice(3)" :key="blog._id">
            <div class="blog-item-top">
              <p class="blog-date">
                {{ new Date(blog.publishDate).toLocaleDateString() }}
              </p>
              <p class="blog-title">{{ blog.title }}</p>
            </div>
            <div v-html="blog.blurb" class="blog-txt"></div>
          </a>
        </div>
      </div>
    </div>
    <div class="contact" id="Contact">
      <div class="contact-line"></div>
      <h4 class="txt70 bodoni">Contact Us</h4>
      <div class="cont-soc-wrap">
        <a :href="social.link" class="soc-top-link" v-for="social in $local.homepage.social" :key="social._id" target="_blank">
          <img :src="social.logo.imageURL" /></a>
      </div>
      <a href="mailto:info@sapirvp.com" class="footer-link">info@sapirvp.com</a>
      <p class="last">©️ Sapir Venture Partners GP LLC</p>
    </div>
  </div>
</template>

<script>
import carousel from "sh-v-owl-carousel";

export default {
  components: {
    carousel,
  },
  data: () => ({
    opennavbar: false,
    moreblogs: false,
  }),
  name: "Home",
};
</script>
